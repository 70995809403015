export const subtitles = [
  "Brand Identity, Ballard Audio",
  "Ballard Audio",
  "Cross Fader, Ballard Audio",
  "Poster, Ballard Audio",
  "App, Ballard Audio",
  "Brand Identity, Unicorn",
  "Graphic Standards Manual, Unicorn",
  "Folders, Unicorn",
  "Posters, Unicorn",
  "Website, Unicorn",
  "Unicorn",
  "Brand Identity, Timeless Garage",
  "Brand Identity, Timeless Garage",
  "Website, Timeless Garage",
  "Workshop Technical Sheet, Timeless Garage",
  "Custom Range Rover LS, Timeless Garage",
  "Brochure, Timeless Garage",
  "Digital Poster, Timeless Garage",
  "Brand Identity, André Malheiro",
  "Website Layout, André Malheiro",
  "Graphic Layout, André Malheiro",
  "Website, André Malheiro",
  "Product Design, Secção",
  "Personal Business Card",
  "Business Card, Pamela Mesquita",
  "Brand Identity, Caprichar",
  "Brand Identity, Caprichar",
  "Film Campaign, Caprichar",
  "Branding, Caprichar",
  "Packaging, Caprichar",
  "Website, Caprichar",
  "Branding, Rats & Bananas",
  "Brand Identity, Rats & Bananas",
  "Website, Rats & Bananas",
  "Magazine, Rats & Bananas",
  "Photo + Graphics, Rats & Bananas",
  "Brand Identity, Raffaele Sarubbo",
  "Website, Raffaele Sarubbo",
  "Website + Outdoor, Raffaele Sarubbo",
  "Social Media, Raffaele Sarubbo",
  "Brand Identity, Bodechic",
  "Interior, Bodechic",
  "Branding, Bodechic",
  "Car vinil, Bodechic",
];
