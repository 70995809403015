import {React} from "react";
import { SwiperSlide } from "swiper/react";
import { ImgWrap, Img, ImgSubtitle, Video } from "./SwiperElements";
import { isMobileOnly, isTablet } from "react-device-detect";
import "react-lazy-load-image-component/src/effects/blur.css";

import { subtitles } from "./Data.js";

export function CreateSlides(start, numberOfSlides, fontColor, index, vhScreen, vidRef) {



    


  const slideImages = [];
  if (isMobileOnly) {
    for (let i = start; i < numberOfSlides; i += 1) {
      if (i===27){
        slideImages[i] = {
        id: "slide" + (i + 1),
        video: require(`../../assets/images/mobile/miguel-lopes-${1 + i}.mp4`)
          .default,
        alt: "slide" + (i + 1),
      };
    } else {
      slideImages[i] = {
        id: "slide" + (i + 1),
        img: require(`../../assets/images/mobile/miguel-lopes-${1 + i}.jpg`)
          .default,
        alt: "slide" + (i + 1),
      };
    }
    // for (let i = start; i < numberOfSlides; i += 1) {
    //   slideImages[i] = {
    //     id: "slide" + (i + 1),
    //     img: require(`../../assets/images/mobile/miguel-lopes-${1 + i}.jpg`)
    //       .default,
    //     alt: "slide" + (i + 1),
    //   };
  }
  } else if (isTablet) {
    for (let i = start; i < numberOfSlides; i += 1) {
      slideImages[i] = {
        id: "slide" + (i + 1),
        img: require(`../../assets/images/tablet/miguel-lopes-${1 + i}.jpg`)
          .default,
        alt: "slide" + (i + 1),
      };
    }
  } else {
    for (let i = start; i < numberOfSlides; i += 1) {
      if (i===27){
        slideImages[i] = {
          id: "slide" + (i + 1),
          video: require(`../../assets/images/desktop/miguel-lopes-${1 + i}.mp4`).default,
          alt: "slide" + (i + 1),
        };
      } else {
        slideImages[i] = {
          id: "slide" + (i + 1),
          img: require(`../../assets/images/desktop/miguel-lopes-${1 + i}.jpg`).default,
          alt: "slide" + (i + 1),
        };
      }
      
    }
    // for (let i = start; i < numberOfSlides; i += 1) {
    //     slideImages[i] = {
    //       id: "slide" + (i + 1),
    //       img: require(`../../assets/images/desktop/miguel-lopes-${1 + i}.jpg`).default,
    //       alt: "slide" + (i + 1),
    //     };
    // }
  }

  const slides = [];
  for (let i = start; i < numberOfSlides; i += 1) {
    if (i===27){
      slides.push(
        <SwiperSlide data-swiper-autoplay="43700" key={slideImages[i].id}>
          <ImgWrap>
            <Video  src={slideImages[i].video} ref={ vidRef } autoPlay loop webkit-playsinline={true} playsInline autoplay/>
          </ImgWrap>
          <ImgSubtitle index={index} fontColor={fontColor} vhScreen={vhScreen} className="subtitle">{subtitles[i]}</ImgSubtitle>
        </SwiperSlide>
      );
    } else {
      slides.push(
        <SwiperSlide  key={slideImages[i].id}>
          <ImgWrap>
            <Img src={slideImages[i].img}  alt={slideImages[i].alt}/>
          </ImgWrap>
          <ImgSubtitle index={index} fontColor={fontColor} vhScreen={vhScreen} className="subtitle">{subtitles[i]}</ImgSubtitle>
        </SwiperSlide>
      );
    }

      // slides.push(
      //   <SwiperSlide  key={slideImages[i].id}>
      //     <ImgWrap>
      //       <Img src={slideImages[i].img}  alt={slideImages[i].alt}/>
      //     </ImgWrap>
      //     <ImgSubtitle index={index} fontColor={fontColor} vhScreen={vhScreen} className="subtitle">{subtitles[i]}</ImgSubtitle>
      //   </SwiperSlide>
      // );
    
   
  }
  return slides;
}
