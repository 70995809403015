import React from "react";
import "swiper/components/navigation/navigation.scss";

import { Description, AboutContainer, Mail } from "./AboutElements";

const AboutSection = ({
  elements
}) => {
  const handleClick = event => {
    event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
  }
  return (
    <AboutContainer
      elements={elements}
      
    >
      <Description>
        I'm a Designer specialized in Branding, Visual Identity and Product Design.
      </Description>
      <Description>
        After several years working with product development, I started to work in graphic design,
        mostly with international companies, creating strategies to build timeless brands.

      </Description>
      <p></p>
      <Mail onClick={ handleClick }
        className="link-about" 
        href="mailto:miguel@miguellopes.info">
          miguel@miguellopes.info
        </Mail>
    </AboutContainer>
  );
};

export default AboutSection;
